
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheTournamentItem',
  components: { Pic },
  props: {
    list: {
      type: Object,
    },
    closed: Boolean,
  },
})
