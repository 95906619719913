
import { defaultToArray } from '@/common/common'
import { YesOrNo } from '@/types'
import { defineComponent, ref } from 'vue'
import TheTournamentItem from '@/modules/membership/tournament/components/TheTournamentItem.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'
import { readTournamentList, TournamentSchema } from '@/modules/membership/tournament/tournament.api'

export default defineComponent({
  name: 'Tournament',
  components: { List, Holder, PageWithHeader, TheTournamentItem },
  setup () {
    const validList = ref<Array<TournamentSchema> | null>(null)
    const closedList = ref<Array<TournamentSchema> | null>(null)

    readTournamentList({ status: YesOrNo.YES, page: 1, pageCount: 50 })
      .then(resp => validList.value = defaultToArray(resp) as Array<TournamentSchema>)

    readTournamentList({ status: 0, page: 1, pageCount: 20 })
      .then(resp => closedList.value = defaultToArray(resp) as Array<TournamentSchema>)

    return {
      validList,
      closedList,
    }
  },
})
